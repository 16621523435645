<template>
  <div class="index">
    <div class="nav">
      <div class="inner">
        <div class="left">
          <img src="../../assets/image/logo.png" alt="logo">
          <span>Adobe</span>
        </div>
        <div class="right">
          <div class="item"><a href="#index">首页</a></div>
          <div class="item"><a href="#list">商品列表</a></div>
          <div class="item"><a href="#reward">免费赠品</a></div>
          <div class="item"><a href="#msg">客户评价</a></div>
          <div class="item"><a href="#problem">常见问题</a></div>
        </div>
      </div>
    </div>
    <div class="body">
      <div class="top" id="index">
        <div class="banner">
          <el-carousel height="575px" indicator-position="none">
            <el-carousel-item v-for="item in banners" :key="item">
              <img :src="item" alt="banner">
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="detail">
          <div class="topImg">
            <img src="../../assets/image/pr1.jpg">
            <img src="../../assets/image/pr2.jpg">
            <img src="../../assets/image/pr3.jpg">
            <img src="../../assets/image/pr4.jpg">
            <img src="../../assets/image/pr5.jpg">
          </div>
          <div class="name">
            最新版【<b>2024版全家桶</b>】全新上线
          </div>
          <div class="intro">
            <p>全家桶限时优惠最低仅需148元，限量1000件，先到先得</p>
            <p>一次购买，终生使用！正版永久激活，免费一键安装，赠送学习视频教程，支持远程安装，<b>安装失败，立即退款</b></p>
          </div>
          <a href="#list" class="btn">立即使用</a>
          <div class="bot">
            <p>版本支持：Windows/Mac</p>
            <div>
              <img src="../../assets/image/logo2.png">
              <span>Adobe Creative Cloud</span>
            </div>
          </div>
        </div>
      </div>
      <div id="list">
        <div class="title">专业技术团队-提供1对1安装服务</div>
        <div class="txt">探索未知的世界，利用一切可能将您的创意变为现实，更快地创建、获取、管理和发布创意内容，从现在开始，惊艳随之而来</div>
        <div class="txt"><b>正版激活，永久使用</b> ！可满足各种需求的创意软件。</div>
        <div class="list">
          <div class="item" v-for="(item, ind) in goodsList" :key="ind">
            <img :src="item.img">
            <div class="name">{{ item.name }}</div>
            <div class="intro">{{ item.intro }}</div>
            <div class="price"><b>{{ item.price }}元</b>/永久使用</div>
            <div class="btn" @click="todetail(item.id)">点击下载</div>
          </div>
        </div>
      </div>
      <div id="reward">
        <div class="title">上千上万赠品 免费下载</div>
        <div class="desc">视频教程，电影音效素材，视频素材，增强插件，应有尽有。</div>
        <div class="list">
          <div class="item">
            <img src="../../assets/image/icon1.png" alt="">
            <div class="p1">教程赠送 1000G</div>
            <div class="p2">购买相应的软件,赠送相应的视频教程,从入门到大神,让你学习更轻松</div>
          </div>
          <div class="item">
            <img src="../../assets/image/icon2.png" alt="">
            <div class="p1">插件赠送 3000+</div>
            <div class="p2">购买相应的软件,赠送相应的插件,效果插件+脚本插件+丰富预设。</div>
          </div>
          <div class="item">
            <img src="../../assets/image/icon3.png" alt="">
            <div class="p1">视频素材赠送 20000+</div>
            <div class="p2">购买相应的软件,赠送相应的素材，各种特效，应有尽有。</div>
          </div>
          <div class="item">
            <img src="../../assets/image/icon4.png" alt="">
            <div class="p1">音效素材赠送 10000+</div>
            <div class="p2">购买相应的软件,赠送相应专用电影音效素材，视听盛宴，值得拥有</div>
          </div>
        </div>
      </div>
      <div id="msg">
        <div class="title">来自用户的真实评价</div>
        <div class="swiper">
          <el-carousel :interval="2000" type="card" height="320px">
            <el-carousel-item v-for="(item, ind) in evList" :key="ind" class="item">
              <div class="text">{{ item.text }}</div>
              <div class="bot">
                <img :src="item.avt">
                <div class="r">
                  <div class="nick">{{ item.nick }}</div>
                  <p>{{ item.user }}</p>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div id="problem">
        <div class="title">经常问的问题</div>
        <div class="list">
          <div class="item" v-for="(item, ind) in problems" :key="ind">
            <div :class="item.flag ? 'top active' : 'top'">
              <p>{{ item.problem }}</p>
              <img src="../../assets/image/icon5.png" v-show="!item.flag" @click="showAns(ind)">
            </div>
            <div class="bot" v-show="item.flag">
              {{ item.ans }}
            </div>
          </div>
        </div>
        <img src="../../assets/image/pbot.png" alt="">
      </div>
    </div>
    <div class="footer">
      <img src="../../assets/image/logo2.png">
      <div class="p1">所有人的创造力</div>
      <div class="p2">摄影、视频、平面设计、插图等等。你需要的一切，无论你的想象力带你到哪里。</div>
      <a href="#index">立即使用</a>
      <div class="footflex">
        <span>精选下载</span>
        <div class="it">
          <img src="../../assets/image/icon6.png">
          <span>Acrobat Pro</span>
        </div>
        <div class="it">
          <img src="../../assets/image/icon7.png">
          <span>Illustrator</span>
        </div>
        <div class="it">
          <img src="../../assets/image/icon8.png">
          <span>Premiere Pro</span>
        </div>
        <div class="it">
          <img src="../../assets/image/icon9.png">
          <span>After Effects</span>
        </div>
        <div class="it">
          <img src="../../assets/image/icon10.png">
          <span>Photoshop</span>
        </div>
      </div>
      <div class="p3">在线客服 9:00-22:00</div>
      <div class="p4">陕西恒泽贝云网络科技有限公司兰州分公司 版权所有 © 2022</div>
    </div>
    <div class="float" :style="{ right: right + 'px' }">
      <div class="img" @click="hover">
        <img src="../../assets/image/QQ.png">
      </div>
      <div class="inner">
        <img src="../../assets/image/二维码.png" alt="">
        <p>扫码联系客服QQ</p>
        <p>7*24小时在线</p>
        <p>123456789</p>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
}

.nav {
  width: 100vw;
  height: 80px;
  box-shadow: 0 5px 10px 1px #ddd;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 99;

  .inner {
    width: 1116px;
    height: 80px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
      font-size: 22px;
      font-weight: bold;
      display: flex;
      align-items: center;

      img {
        width: 40px;
        height: 30px;
        margin-right: 10px;
      }
    }

    .right {
      display: flex;
      align-items: center;

      .item {
        margin: 0 20px;
        cursor: pointer;

        a {
          color: #001E36;
          text-decoration: none;
          font-weight: bold;
          font-size: 16px;
        }
      }
    }

  }
}

.body {
  margin-top: 80px;
  background: linear-gradient(to right, lightblue, rgb(255, 218, 224), rgb(205, 233, 243));
  overflow: hidden;

  .top {
    width: 1115px;
    margin: 20px auto;
    padding: 20px 50px;
    background-color: #fff;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;

    .banner {
      width: 515px;
      height: 575px;
      background-color: #ccc;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .detail {
      margin-left: 30px;
      width: 455px;

      .topImg {
        img {
          width: 70px;
          height: 70px;
        }
      }

      .name {
        font-size: 35px;
        font-weight: bold;
        margin-top: 20px;
        line-height: 1.5;

        b {
          color: red;
        }
      }

      .intro {
        font-size: 19px;
        margin-top: 20px;

        p {
          margin: 10px 0;
          line-height: 1.7;

          &:nth-child(2) {
            color: red;
          }
        }
      }

      .btn {
        display: block;
        width: 192px;
        height: 56px;
        background: #4313A5;
        border-radius: 8px;
        line-height: 56px;
        text-align: center;
        font-size: 19.2px;
        font-weight: bold;
        color: #ffffff;
        text-decoration: none;
        margin: 20px 0;
      }

      .bot {
        margin-top: 30px;

        &>div {
          display: flex;
          align-items: center;
          font-size: 20px;
          margin-top: 10px;
          font-weight: normal;

          img {
            width: 40px;
            height: 40px;
            border-radius: 5px;
            margin-right: 10px;
          }
        }
      }

    }
  }

  #list {
    width: 1115px;
    margin: 50px auto;
    padding: 50px;
    background-color: #fff;
    border-radius: 20px;

    .title {
      height: 53px;
      line-height: 53px;
      font-size: 32px;
      font-weight: bold;
      text-align: center;
      margin-bottom: 15px;
    }

    .txt {
      line-height: 36.8px;
      color: #000000;
      font-size: 16px;
      text-align: center;

      b {
        color: red;
      }
    }

    .list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 30px;

      .item {
        width: 300px;
        height: 387.2px;
        background: #FFFFFF;
        box-shadow: 0px 3px 16px 0px rgba(0, 27, 48, 0.15);
        border-radius: 10px;
        box-sizing: border-box;
        padding: 40px 0 0 30px;
        margin-right: 32px;
        margin-bottom: 33px;

        img {
          width: 100px;
          height: 100px;
        }

        .name {
          height: 47px;
          line-height: 47px;
          margin-top: 21px;
          font-size: 24px;
          color: red;
          font-weight: bold;
        }

        .intro {
          height: 60px;
          line-height: 30px;
          color: #666666;
          font-size: 14.4px;
          font-weight: 400;
        }

        .price {
          height: 37px;
          line-height: 37px;
          color: #0D0D25;
          font-weight: bold;
          margin-top: 15px;
          margin-bottom: 11px;
          font-size: 16px;

          b {
            color: red;
          }
        }

        .btn {
          width: 160px;
          height: 40px;
          line-height: 40px;
          background: #4313A5;
          border-radius: 8px;
          font-size: 16px;
          color: #FFFFFF;
          font-weight: bold;
          text-align: center;
          cursor: pointer;
        }
      }
    }
  }

  #reward {
    width: 1115px;
    margin: 70px auto 0;
    padding: 30px;
    background-color: #fff;

    .title {
      height: 52px;
      line-height: 52px;
      font-size: 32px;
      color: #0D0D25;
      font-weight: bold;
      margin-top: 46px;
      text-align: center;
    }

    .desc {
      height: 37px;
      line-height: 37px;
      margin-top: 10px;
      text-align: center;
      font-size: 16px;
    }

    .list {
      display: flex;

      .item {
        margin-left: 8px;
        margin-right: 8px;
        height: 176px;
        box-sizing: border-box;
        padding: 17px;
        border-radius: 8px;

        &:nth-of-type(1) {
          margin-top: 117px;
          background: #F5EBFE;
          border: 2px solid #D1C0E1;
        }

        &:nth-of-type(3) {
          margin-top: 117px;
          background: #EBEDFF;
          border: 2px solid #6F6FB6;
        }

        &:nth-of-type(2) {
          margin-top: 53px;
          background: #FEF5E6;
          border: 2px solid #CFA97E;
        }

        &:nth-of-type(4) {
          margin-top: 53px;
          background: #FDE7F1;
          border: 2px solid #CE85A0;
        }

        img {
          width: 32px;
          height: 32px;
        }

        .p1 {
          height: 40px;
          line-height: 40px;
          font-size: 18px;
          color: #000000;
          margin-top: 10px;
          font-weight: bold;
        }

        .p2 {
          line-height: 20px;
          font-size: 14px;
          color: #000000;
          margin-top: 10px;
        }
      }
    }
  }

  #msg {
    width: 1115px;
    margin: 0 auto;
    padding: 30px;
    background-color: #f4f4f4;

    .title {
      height: 52px;
      line-height: 52px;
      font-weight: bolder;
      color: #0D0D25;
      text-align: center;
      padding-top: 46px;
      margin-bottom: 53px;
      font-size: 32px;
    }

    .swiper {
      width: 1035px;
      margin: 0 auto;

      .item {
        height: 320px;
        background: #FFFFFF;
        box-shadow: 3px 5px 16px 0px rgba(0, 72, 129, 0.1);
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .text {
          padding: 73px 29px 0 37px;
          line-height: 36.8px;
          color: #0D0D25;
          font-size: 22.4px;
          font-weight: bold;
        }

        .bot {
          display: flex;
          align-items: center;
          height: 80px;
          padding: 0 42px 0 42px;
          margin-bottom: 42px;

          img {
            width: 80px;
            height: 80px;
            border-radius: 50%;
            margin-right: 15px;
          }

          .r {
            .nick {
              height: 40px;
              line-height: 40px;
              color: #0D0D25;
              font-weight: bold;
              font-size: 19.2px;
            }

            p {
              height: 16px;
              line-height: 16px;
              color: #9d9d9d;
              font-size: 14.4px;
            }
          }
        }
      }
    }




  }

  #problem {
    width: 1115px;
    margin: 0 auto;
    margin-bottom: 50px;
    background-color: #fff;
    overflow: hidden;

    .title {
      height: 52px;
      line-height: 52px;
      font-size: 32px;
      color: #0d0d0d;
      font-weight: bold;
      text-align: center;
      padding-top: 46px;
    }

    .list {
      margin-top: 30px;
      padding: 50px;

      .item {
        margin-bottom: 24px;
        box-shadow: 3px 5px 16px 0px rgba(0, 72, 129, 0.2);

        .top {
          width: 100%;
          height: 67px;
          padding: 0 16px 0 29px;
          font-size: 19.2px;
          line-height: 67px;
          color: #30323D;
          background-color: #ffffff;
          font-weight: bold;
          display: flex;
          justify-content: space-between;
          align-items: center;

          img {
            cursor: pointer;
            width: 34px;
            height: 34px;
          }

          &.active {
            background-color: #4313A5;
            color: #ffffff;
            border-radius: 0;
          }
        }

        .bot {
          padding: 14px 29px;
          background-color: #ffffff;
          line-height: 38px;
          font-size: 18px;
          color: #878991;
        }
      }
    }

    &>img {
      width: 86%;
      display: block;
      transition: all .3s;
      box-shadow: rgba(23, 25, 29, 0.2) 0px 16px 16px -16px, rgba(23, 25, 29, 0.03) 0px 14px 20px 0px, rgba(23, 25, 29, 0.04) 0px 0px 0px;
      margin: 30px auto;
      border-radius: 15px;
      border: 1px solid #f5f5f5;

      &:hover {
        transform: scale(1.05);
      }
    }

  }


}

.footer {
  width: 100%;
  background-color: #ffffff;
  height: 590px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  &>img {
    width: 80px;
    height: 80px;
    margin-top: 90px;
  }

  .p1 {
    height: 44px;
    line-height: 44px;
    font-size: 24px;
    font-weight: bold;
    color: #0d0d0d;
    margin-top: 20px;
  }

  .p2 {
    height: 36px;
    line-height: 36px;
    font-size: 14.4px;
    color: #0d0d25;
    margin-top: 4px;
  }

  &>a {
    display: block;
    width: 192px;
    height: 56px;
    line-height: 56px;
    background: #4313A5;
    border-radius: 28px;
    font-size: 19.2px;
    text-align: center;
    font-weight: bold;
    color: #FFFFFF;
    text-decoration: none;
    margin-top: 22px;
  }

  .footflex {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12.8px;
    margin: 50px 0 10px;
    padding-bottom: 15px;
    border-bottom: 1px solid #ccc;

    .it {
      display: flex;
      align-items: center;
      margin: 0 10px;
      cursor: pointer;

      img {
        width: 18px;
        height: 18px;
        margin-right: 5px;
      }
    }
  }

  .p3 {
    margin-top: 30px;
    height: 33px;
    line-height: 33px;
    font-size: 12.8px;
    color: #333333;
  }

  .p4 {
    height: 33px;
    line-height: 33px;
    font-size: 12.8px;
    color: #333333;
  }

}

.float {
  position: fixed;
  bottom: 120px;
  display: flex;
  transition: .5s all;

  .img {
    width: 60px;
    height: 60px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;

    img {
      width: 40px;
      height: 40px;
    }
  }

  .inner {
    background-color: #0590DF;
    border-radius: 5px;
    padding: 10px;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;

    &>img {
      width: 80px;
      height: 80px;
    }

    p {
      margin: 5px 0;
    }
  }
}
</style>

<script>

export default {
  data() {
    return {
      right: -144,
      banners: [
        require('../../assets/image/banner1.jpeg'),
        require('../../assets/image/banner2.jpeg'),
        require('../../assets/image/banner3.jpeg')
      ],
      problems: [
        {
          problem: '我不会安装怎么办？',
          ans: '我们有非常详细的安装教程，并且有专门的技术保证你能安装成功，一对一给您远程安装，不成功立即退款。 ',
          flag: false
        }, {
          problem: '可以安装几台电脑？',
          ans: '目前尚未对安装台数有限制，支持无限次重装。',
          flag: false
        }, {
          problem: '电脑重装系统了，怎么办？',
          ans: '电脑重新系统安装了也不影响再次安装，我们支持无限次重装。',
          flag: false
        }, {
          problem: '我可以免费使用吗？',
          ans: '软件是收费的，我们提供售前售后咨询，请为我们辛勤的服务付费，谢谢。',
          flag: false
        }, {
          problem: '我不会使用Premiere，怎么办？',
          ans: '我们会赠送100G教学视频，从最基础的开始教你怎么使用。',
          flag: false
        }
      ],
      evList: [
        {
          text: '“下载很方便，也安心，赠送的插件，字体也有很多，工作起来也方便多了，非常感谢！”',
          avt: require('../../assets/image/avt1.png'),
          nick: '设计工作室',
          user: '设计师 '
        }, {
          text: '“我是一个初学者，客服赠送了我好多教程，都是视频教学，终于可以好好学学ps了！”',
          avt: require('../../assets/image/avt2.png'),
          nick: '欣欣',
          user: '初学者 '
        }, {
          text: '“技术小哥技术很厉害，我自己搞不定，他一会会就搞定了，专业的就是不一样。”',
          avt: require('../../assets/image/avt3.png'),
          nick: '浅色夏沫',
          user: '学生 '
        }, {
          text: '“我是一个纯粹的电脑小白，下载过程中有点坎坷，但是客服耐心解答，终于下载好了，感谢！”',
          avt: require('../../assets/image/avt4.png'),
          nick: '森林中',
          user: 'UI设计师'
        }
      ],
      goodsList: [
        {
          id: 1,
          name: 'Adobe 软件全套',
          intro: '正版激活，17款软件，完美支持window和mac系统',
          price: 248,
          otPrice:1199,
          img: require('../../assets/image/adobe.jpg')
        }, {
          id: 2,
          name: 'Adobe Photoshop',
          intro: '一键安装，正版激活，永久使用支持Win7/8/10/11,MAC等多个版本',
          price: 78,
          otPrice:999,
          img: require('../../assets/image/pr1.jpg')
        }, {
          id: 3,
          name: 'Adobe Premiere',
          intro: '一键安装，正版激活，永久使用支持Win7/8/10/11,MAC等多个版本',
          price: 78,
          otPrice:999,
          img: require('../../assets/image/pr2.jpg')
        },{
          id: 4,
          name: 'Adobe After Effects',
          intro: '一键安装，正版激活，永久使用支持Win7/8/10/11,MAC等多个版本',
          price: 78,
          otPrice:999,
          img: require('../../assets/image/pr3.jpg')
        },{
          id: 5,
          name: 'Adobe Dreamweaver',
          intro: '一键安装，正版激活，永久使用支持Win7/8/10/11,MAC等多个版本',
          price: 68,
          otPrice:999,
          img: require('../../assets/image/pr4.jpg')
        },{
          id: 6,
          name: 'Adobe Illustrator',
          intro: '一键安装，正版激活，永久使用支持Win7/8/10/11,MAC等多个版本',
          price: 78,
          otPrice:999,
          img: require('../../assets/image/pr5.jpg')
        },{
          id: 7,
          name: 'Adobe Animate',
          intro: '一键安装，正版激活，永久使用支持Win7/8/10/11,MAC等多个版本',
          price: 78,
          otPrice:999,
          img: require('../../assets/image/good2.jpg')
        },{
          id: 8,
          name: 'Adobe Audition',
          intro: '一键安装，正版激活，永久使用支持Win7/8/10/11,MAC等多个版本',
          price: 78,
          otPrice:999,
          img: require('../../assets/image/good3.jpg')
        },{
          id: 9,
          name: 'Adobe InDesign',
          intro: '一键安装，正版激活，永久使用支持Win7/8/10/11,MAC等多个版本',
          price: 78,
          otPrice:1699,
          img: require('../../assets/image/good4.jpg')
        },{
          id: 10,
          name: 'Adobe Lightroom',
          intro: '一键安装，正版激活，永久使用支持Win7/8/10/11,MAC等多个版本',
          price: 78,
          otPrice:999,
          img: require('../../assets/image/good5.jpg')
        },{
          id: 11,
          name: 'Adobe PDF',
          intro: '一键安装，正版激活，永久使用支持Win7/8/10/11,MAC等多个版本',
          price: 68,
          otPrice:999,
          img: require('../../assets/image/good7.jpg')
        }
      ]
    }
  },
  methods: {
    todetail(id) {
      this.$router.push('/cate/' + id)
    },
    showAns(ind) {
      this.problems.forEach(e => {
        e.flag = false
      })
      this.problems[ind].flag = true;
    },
    hover() {
      if (this.right == -144) {
        this.right = 0
      } else {
        this.right = -144
      }
    },

  },
  mounted() {
    const hash = this.$route.hash;
    
    if (hash) {
      const targetElement = document.querySelector(hash);
      
      if (targetElement) {
        window.scrollTo(0,targetElement.offsetTop);
      }
    }
  }
}
</script>

